// Customizable Area Start
import React from "react";
import { withRouter } from "react-router-dom";
import MerchantOverviewController, { Props } from "./MerchantOverviewController.web";
import Header from "../../../../components/src/Header.web";
import Sidebar from "../../../../components/src/Sidebar.web";
import { createStyles, Theme, withStyles } from "@material-ui/core/styles";
import { Button, InputAdornment, MenuItem, Select, Typography, Dialog, OutlinedInput } from "@material-ui/core";
import {
    OptionsIcon,
    LocationIcon,
    CrossIcon
} from "../../../../components/src/IconAssets";
import { iconArrowDown, imageMap } from "../assets";
import CustomDataGrid, { ColumnsProps } from "../../../../components/src/CustomDataGrid.web";
import UnderwriterScreenStepper from "./UnderwriterScreenStepper.web";

const ownersColumns: ColumnsProps[] = [
    { key: 'slno', label: 'Sl. No' },
    { key: 'owner_name', label: 'Owner Name' },
    { key: 'phone', label: 'Phone' },
    { key: 'email', label: 'Email' },
    { key: 'address', label: 'Address' },
    { key: 'ssn', label: 'SSN' }
];

const historyColumns: ColumnsProps[] = [
    { key: 'business_name', label: 'Merchant Business Name' },
    { key: 'details', label: 'Details' },
    { key: 'submitted', label: 'Submitted' },
    { key: 'mid', label: 'MID' },
    { key: 'iso', label: 'ISO' },
    { key: 'owners', label: 'Owners' }
];
// Customizable Area End

export class MerchantOverview extends MerchantOverviewController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        const { classes } = this.props;
        const { owners, submissionHistory } = this.state;
        return (
            // Customizable Area Start
            <>
                <Header />
                <Sidebar />
                <main className={`page-main`} data-test-id="Main">
                    <Typography variant="h3" className={classes.pageTitle}>Merchant Overview</Typography>
                    <UnderwriterScreenStepper />
                    <form
                        data-test-id="filterForm"
                        className={classes.filterForm}
                        onSubmit={(e) => e.preventDefault()}
                    >
                        <div className={classes.formField}>
                            <label htmlFor="status">Status</label>
                            <Select
                                id="status"
                                name="status"
                                className="select-box"
                                variant="outlined"
                                data-test-id="statusInput"
                                endAdornment={
                                    <InputAdornment position="end">
                                        <img src={iconArrowDown} alt="downarrow" width={24} height={24} />
                                    </InputAdornment>
                                }
                            >
                                <MenuItem className="select-option" value="status1">Status 1</MenuItem>
                                <MenuItem className="select-option" value="status2">Status 2</MenuItem>
                                <MenuItem className="select-option" value="status3">Status 3</MenuItem>
                            </Select>
                        </div>
                        <div className={classes.formField}>
                            <label htmlFor="details">Details</label>
                            <Select
                                id="details"
                                name="details"
                                className="select-box"
                                variant="outlined"
                                data-test-id="detailsInput"
                                endAdornment={
                                    <InputAdornment position="end">
                                        <img src={iconArrowDown} alt="downarrow" width={24} height={24} />
                                    </InputAdornment>
                                }
                            >
                                <MenuItem className="select-option" value="details1">Details 1</MenuItem>
                                <MenuItem className="select-option" value="details2">Details 2</MenuItem>
                                <MenuItem className="select-option" value="details3">Details 3</MenuItem>
                            </Select>
                        </div>
                        <Button type="submit">Search</Button>
                    </form>
                    <div className={classes.merchantDataBox}>
                        <div className="boxHead">
                            <Typography variant="h5">Business Info</Typography>
                            <Button
                                data-test-id="editBtn"
                                onClick={() => this.setState({ isDialogBuyRateOpen: true })}
                            >
                                <OptionsIcon />
                            </Button>
                        </div>
                        <div className={classes.businessInfo + " box-body"}>
                            <ul>
                                <li><label>MID</label> 722</li>
                                <li><label>Email</label> abc@example.com</li>
                                <li><label>Merchant</label> test</li>
                                <li><label>Phone</label> 9876543210</li>
                                <li><label>DBA</label> Abc Deli</li>
                                <li><label>Entity Type</label> Limited Liability Company</li>
                                <li><label>Industry Type</label> Adult Entertainment</li>
                                <li><label>FEIN</label> 12-7654321</li>
                                <li><label>Industry Type</label> 555, Main Street, New York, New York</li>
                                <li><label>App ID</label> Adult Entertainment</li>
                                {/* <li><label>Country</label> New York</li> */}
                                <li><label>Start Date</label> 12/14/2023</li>
                            </ul>
                            <div className="locationMap">
                                <img src={imageMap} width={"100%"} />
                                <Button>Update <LocationIcon /></Button>
                            </div>
                        </div>
                    </div>
                    <div className={classes.merchantDataBox}>
                        <div className="boxHead">
                            <Typography variant="h5">Owners</Typography>
                        </div>
                        <div className={classes.dataTblWrapper}>
                            <CustomDataGrid columns={ownersColumns} rows={owners} rowsPerPage={owners.length} loading={false} />
                        </div>
                    </div>
                    <div className={classes.merchantDataBox}>
                        <div className="boxHead">
                            <Typography variant="h5">Submission Info</Typography>
                        </div>
                        <div className={classes.submissionInfo + " box-body"}>
                            <ul>
                                <li><span>UW</span> Aaron Jackson</li>
                                <li><span>Funding Coordinator</span> Mee</li>
                                <li><span>ISO Manager</span> Test Deli</li>
                                <li><span>Support Rep</span> Test Deli</li>
                                <li><span>ISO</span> 9876</li>
                                <li><span>Submitted By</span> aaron@smartermerchant.com</li>
                            </ul>
                            <div className="submissionNote">
                                <label>Submission Notes</label>
                                <textarea rows={3}>This can be a multiline placeholder</textarea>
                            </div>
                        </div>
                    </div>
                    <div className={classes.merchantDataBox}>
                        <div className="boxHead">
                            <Typography variant="h5">Owners</Typography>
                        </div>
                        <div className={classes.dataTblWrapper}>
                            <CustomDataGrid columns={historyColumns} rows={submissionHistory} rowsPerPage={submissionHistory.length} loading={false} />
                        </div>
                    </div>
                    <div className={classes.merchantDataBox}>
                        <div className="boxHead">
                            <Typography variant="h5">Funding Terms</Typography>
                            <Button><OptionsIcon /></Button>
                        </div>
                        <div className={classes.fundingTerms + " box-body"}>
                            <div className={classes.formField}>
                                <label htmlFor="dealType">Deal Type</label>
                                <Select
                                    id="dealType"
                                    name="dealType"
                                    className="select-box"
                                    variant="outlined"
                                    data-test-id="dealTypeInput"
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <img src={iconArrowDown} alt="downarrow" width={24} height={24} />
                                        </InputAdornment>
                                    }
                                >
                                    <MenuItem className="select-option" value="type1">Type 1</MenuItem>
                                    <MenuItem className="select-option" value="type2">Type 2</MenuItem>
                                    <MenuItem className="select-option" value="type3">Type 3</MenuItem>
                                </Select>
                            </div>
                            <ul className="fundingInfo">
                                <li><span>Funded</span></li>
                                <li><span>Balance</span></li>
                                <li><span>RTR</span></li>
                                <li><span>To Recoup</span></li>
                                <li><span>Factor</span> 1.30</li>
                                <li><span>CTD</span> $56709</li>
                                <li><span>Term</span></li>
                                <li><span>Number of Payoffs</span></li>
                                <li><span>Commission</span> 15%</li>
                                <li><span>Payoff Amount</span> $56709</li>
                            </ul>
                        </div>
                    </div>
                    <div className={classes.merchantDataBox}>
                        <div className="boxHead">
                            <Typography variant="h5">Banking Info</Typography>
                            <Button><OptionsIcon /></Button>
                        </div>
                        <div className={classes.bankingInfo}>
                            <div className="info-list">
                                <div><span>Bank Name:</span> Abc Bank</div>
                                <div><span>Wire ABA:</span> 12345678</div>
                                <div><span>ACH ABA:</span> 12345678</div>
                                <div><span>Account Number:</span> 12345678</div>
                            </div>
                            <div className="additional-info">
                                <label>Additional Wiring Instructions</label>
                                <textarea rows={3}>Additional Wiring Instructions</textarea>
                            </div>
                        </div>
                    </div>
                    <Dialog
                        className={classes.addingDealDialog}
                        open={this.state.isDialogBuyRateOpen}
                        onClose={this.handleDialogClose}
                    >
                        <div className="dialog-header">
                            <Typography variant="h4">Buy Rates</Typography>
                            <Button onClick={this.handleDialogClose}><CrossIcon /></Button>
                        </div>
                        <div className={classes.dialogForm}>
                            <div className={classes.formField + " dataField"}>
                                <label htmlFor="dealType" className="input-label">Deal Type</label>
                                <Select
                                    id="dealType"
                                    name="dealType"
                                    className="select-box"
                                    variant="outlined"
                                    data-test-id="deal_type_input"
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <img src={iconArrowDown} alt="downarrow" width={24} height={24} />
                                        </InputAdornment>
                                    }
                                >
                                    <MenuItem className="select-option" value="deal1">Deal Type 1</MenuItem>
                                    <MenuItem className="select-option" value="deal2">Deal Type 2</MenuItem>
                                    <MenuItem className="select-option" value="deal3">Deal Type 3</MenuItem>
                                </Select>
                            </div>
                            <div className={classes.formField + " dataField"}>
                                <label htmlFor="clacSetting" className="input-label">Calc Setting</label>
                                <Select
                                    id="clacSetting"
                                    name="clacSetting"
                                    className="select-box"
                                    variant="outlined"
                                    data-test-id="clacSettingInput"
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <img src={iconArrowDown} alt="downarrow" width={24} height={24} />
                                        </InputAdornment>
                                    }
                                >
                                    <MenuItem className="select-option" value="clacSetting1">Calc Setting 1</MenuItem>
                                    <MenuItem className="select-option" value="clacSetting2">Calc Setting 2</MenuItem>
                                    <MenuItem className="select-option" value="clacSetting3">Calc Setting 3</MenuItem>
                                    <MenuItem className="select-option" value="clacSetting1">Calc Setting 4</MenuItem>
                                </Select>
                            </div>
                            <div className={classes.formField + " dataField"}>
                                <label htmlFor="funded" className="input-label">Funded</label>
                                <OutlinedInput
                                    className="input-field"
                                    id="funded"
                                    name="funded"
                                    placeholder="Funded"
                                    data-test-id="fundedInput"
                                />
                            </div>
                            <div className={classes.formField + " dataField"}>
                                <label htmlFor="rate" className="input-label">Rate</label>
                                <OutlinedInput
                                    className="input-field"
                                    id="rate"
                                    name="rate"
                                    placeholder="rate"
                                    data-test-id="rateInput"
                                />
                            </div>
                            <div className={classes.formField + " dataField"}>
                                <label htmlFor="payments" className="input-label">Payments</label>
                                <OutlinedInput
                                    className="input-field"
                                    id="payments"
                                    name="payments"
                                    placeholder="payments"
                                    data-test-id="paymentsInput"
                                />
                            </div>
                            <div className={classes.formField + " dataField"}>
                                <label htmlFor="commission" className="input-label">Commission</label>
                                <Select
                                    id="commission"
                                    name="commission"
                                    className="select-box"
                                    variant="outlined"
                                    data-test-id="commissionInput"
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <img src={iconArrowDown} alt="downarrow" width={24} height={24} />
                                        </InputAdornment>
                                    }
                                >
                                    <MenuItem className="select-option" value="commission1">Commission 1</MenuItem>
                                    <MenuItem className="select-option" value="commission2">Commission 2</MenuItem>
                                    <MenuItem className="select-option" value="commission3">Commission 3</MenuItem>
                                </Select>
                            </div>
                            <div className={classes.formField + " seperateField"}>
                                <label htmlFor="commission">Enter Offer Notes Here</label>
                                <textarea rows={4}></textarea>
                                <Button>Send Offer</Button>
                            </div>
                            <div className={classes.formField + " seperateField"}>
                                <label htmlFor="commission">Add stip</label>
                                <textarea rows={4}></textarea>
                                <Button>Send Stip</Button>
                            </div>
                        </div>
                    </Dialog>
                </main>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const styles = (_theme: Theme) => createStyles({
    pageTitle: {
        fontSize: 24,
        lineHeight: "normal",
        fontWeight: 500,
        marginBottom: 30,
        "@media (max-width: 767px)": {
            fontSize: 22,
        }
    },
    filterForm: {
        display: "flex",
        flexWrap: "wrap",
        alignItems: "flex-end",
        gap: 25,
        margin: "40px 0",
        "@media (max-width: 767px)": {
            gap: 15,
        },
        "& button, button:hover": {
            maxWidth: 208,
            width: "100%",
            padding: 12,
            background: "#503A65",
            borderRadius: 8,
            color: "#FFFFFF",
            fontSize: 18,
            lineHeight: "normal",
            fontWeight: 600,
            "@media (max-width: 991px)": {
                maxWidth: "calc(100% / 3 - 50px)",
            },
            "@media (max-width: 767px)": {
                maxWidth: "100%",
                fontSize: 16,
                height: 38,
            },
        }
    },
    formField: {
        display: "flex",
        flexDirection: "column",
        gap: 5,
        width: "calc(100% / 3)",
        "@media (max-width: 767px)": {
            width: "calc(100% / 2 - 8px)",
        },
        "@media (max-width: 480px)": {
            width: "100%",
        },
        "& fieldset": {
            display: "none",
        },
        "& label": {
            fontSize: 16,
            lineHeight: "normal",
            color: "#334155",
            "@media (max-width: 767px)": {
                fontSize: 14,
            }
        },
        "& .select-box, .MuiOutlinedInput-root": {
            width: "100%",
            height: 44,
            fontSize: 16,
            color: "#000",
            display: "flex",
            alignItems: "center",
            borderRadius: 8,
            border: "1px solid #E1D8E5",
            boxSizing: "border-box",
            "@media (max-width: 767px)": {
                height: 38
            },
            "& .MuiSelect-icon": {
                display: "none",
            },
            "& .MuiSelect-root": {
                height: "100%",
                boxSizing: "border-box",
            }
        },
    },
    merchantDataBox: {
        "&:not(:last-child)": {
            marginBottom: 40,
        },
        "& .boxHead": {
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 20,
            "@media (max-width: 767px)": {
                marginBottom: 10,
            },
            "& h5": {
                fontSize: 22,
                lineHeight: "normal",
                fontWeight: 500,
                width: "auto",
            },
            "& button, button:hover": {
                minWidth: "auto",
                borderRadius: "50%",
            }
        },
        "& .box-body ul": {
            listStyleType: "none",
            margin: 0,
            padding: 0,
            display: "flex",
            flexWrap: "wrap",
            "& li": {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                fontSize: 16,
                lineHeight: "normal",
                textAlign: "right",
                wordBreak: "break-word",
                "@media (max-width: 767px)": {
                    fontSize: 14,
                    rowGap: "10px !important",
                },
                "@media (max-width: 639px)": {
                    width: "100% !important",
                },
                "& span,label": {
                    color: "#818181",
                    textAlign: "left",
                },
            }
        },
    },
    businessInfo: {
        padding: 16,
        gap: 39,
        borderRadius: 16,
        border: "1px solid #E1D8E5",
        boxSizing: "border-box",
        display: "flex",
        flexWrap: "wrap",
        "@media (max-width: 767px)": {
            gap: 20,
        },
        "& ul": {
            width: "calc(100% - 275px)",
            columnGap: 40,
            rowGap: 15,
            "@media (max-width: 767px)": {
                width: "100%",
            },
            "& li": {
                width: "calc(50% - 20px)",
                columnGap: 10,
                "@media (max-width: 991px)": {
                    width: "100%",
                },
                "& label": {
                    width: "fit-content",
                }
            }
        },
        "& .locationMap": {
            maxWidth: 236,
            width: "100%",
            position: "relative",
            "@media (max-width: 767px)": {
                maxWidth: "100%",
                maxHeight: 180,
            },
            "& img": {
                objectFit: "cover",
                height: "100%",
            },
            "& button, button:hover": {
                background: "#FFF",
                color: "#503A65",
                fontSize: 18,
                lineHeight: "normal",
                fontWeight: 600,
                borderRadius: 8,
                padding: "12px 16px",
                position: 'absolute',
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                "& svg": {
                    marginLeft: 8,
                }
            }
        }
    },
    dataTblWrapper: {
        "& .custom-datagrid": {
            minHeight: "auto",
            borderColor: "#E1D8E5",
            "& table .empty-msg": {
                position: "relative !important",
                display: "table-cell !important",
                textAlign: "center",
            },
        },
        "& .pagination-wrapper": {
            display: "none",
        },
    },
    submissionInfo: {
        display: "flex",
        flexWrap: "wrap",
        border: "1px solid #E1D8E5",
        borderRadius: 12,
        padding: 15,
        gap: 40,
        "@media (max-width: 991px)": {
            gap: 25,
        },
        "& ul": {
            columnGap: 40,
            rowGap: 15,
            width: "calc(66.66% - 20px)",
            "@media (max-width: 991px)": {
                width: "100%",
            },
            "& li": {
                width: "calc(50% - 20px)",
                columnGap: 10,
            }
        },
        "& .submissionNote": {
            width: "calc(33.33% - 20px)",
            "@media (max-width: 991px)": {
                width: "100%",
            },
            "& label, textarea": {
                width: "100%",
                "@media (max-width: 767px)": {
                    fontSize: 14,
                },
            },
            "& textarea": {
                border: "1px solid #E1D8E5",
                borderRadius: 8,
                color: "#334155",
                padding: 8,
                marginTop: 8,
                boxSizing: "border-box",
                resize: "none",
            }
        }
    },
    fundingTerms: {
        border: "1px solid #E1D8E5",
        borderRadius: 12,
        padding: 15,
        "& ul.fundingInfo": {
            alignItems: "center",
            columnGap: 40,
            rowGap: 15,
            marginTop: 20,
            "& li": {
                width: "calc(50% -  20px)",
                columnGap: 10,
            }
        }
    },
    bankingInfo: {
        padding: 15,
        borderRadius: 12,
        border: "1px solid #E1D8E5",
        boxSizing: "border-box",
        "& .info-list": {
            gap: 15,
            marginBottom: 20,
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            "@media (max-width: 991px)": {
                columnGap: 40,
            },
            "& >div": {
                fontSize: 16,
                "& span": {
                    color: "#818181",
                },
                "@media (max-width: 639px)": {
                    width: "100%",
                },
                "@media (max-width: 991px)": {
                    width: "calc(100% / 2 - 20px)",
                    display: "flex",
                    justifyContent: "space-between",
                },
                "@media (max-width: 767px)": {
                    fontSize: 14,
                },
            }
        },
        "& .additional-info": {
            display: "flex",
            flexDirection: "column",
            fontSize: 16,
            gap: 8,
            "& textarea": {
                resize: "none",
                border: "1px solid #E1D8E5",
                borderRadius: 8,
                padding: 10,
                color: "#334155",
            },
            "@media (max-width: 767px)": {
                fontSize: 14,
                "& textarea": {
                    fontSize: 14,
                }
            },
        }
    },
    addingDealDialog: {
        "& *": {
            fontFamily: "Fira Sans, sans-serif",
        },
        "& .MuiDialog-paper": {
            width: "100%",
            borderRadius: 8,
            padding: 20,
            paddingBottom: 0,
            boxShadow: "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 25px 50px 0px rgba(0, 0, 0, 0.09)",
        },
        "& .dialog-header": {
            display: "inline-flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 40,
            "& button": {
                minWidth: "auto",
                padding: 0
            },
            "& h4": {
                fontSize: 24,
                lineHeight: "normal",
                fontWeight: 600,
                color: "#202020",
                "@media (max-width: 767px)": {
                    fontSize: 20,
                },
            },
        },
    },
    dialogForm: {
        display: "flex",
        flexWrap: "wrap",
        gap: 16,
        "& .dataField": {
            width: "calc(50% - 8px)",
            "@media (max-width: 480px)": {
                width: "100%",
            },
            marginBottom: 20,
        },
        "& .seperateField": {
            width: "100%",
            "&:last-child": {
                marginBottom: 20,
            },
            "& textarea": {
                resize: "none",
                padding: 10,
                borderRadius: 8,
                fontSize: 16,
                border: "1px solid #E1D8E5",
            },
            "& button": {
                padding: "10px 16px",
                background: "#503A65",
                borderRadius: 8,
                width: "fit-content",
                fontSize: 16,
                lineHeight: "normal",
                fontWeight: 600,
                color: "#FFFFFF",
                marginLeft: "auto",
                marginTop: 10,
                textTransform: "capitalize",
                "@media (max-width: 767px)": {
                    fontSize: 14,
                    padding: "8px 14px",
                },
            }
        },
    }
});

export default withStyles(styles)(withRouter(MerchantOverview));
// Customizable Area End
